(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('OrderRefDialogController', OrderRefDialogController);

  OrderRefDialogController.$inject = [
    '$translate',
    '$translatePartialLoader',
    '$uibModalInstance',
    'billRef',
    'orderRef',
    'participations'
  ];

  function OrderRefDialogController($translate, $translatePartialLoader, $uibModalInstance, billRef, orderRef, participations) {
    $translatePartialLoader.addPart('dialog');
    $translate.refresh();

    var ctrl = this;
    ctrl.participations = participations;
    ctrl.participationId = ctrl.participations[0].id;
    ctrl.orderRef = orderRef;
    ctrl.billRef = billRef;

    var participation = ctrl.participations[0];

    ctrl.societyName = participation.facturationSocietyName;
    ctrl.societyAddress = participation.facturationSocietyAddress;
    ctrl.societyAddress2 = participation.facturationSocietyAddress2;
    ctrl.societyCp = participation.facturationSocietyCp;
    ctrl.societyCity = participation.facturationSocietyCity;

    ctrl.onSelectParticipation = function () {
      var participationSelected = _.find(ctrl.participations, function (o) {
        return o.id === ctrl.participationId;
      });
      if (participationSelected) {
        ctrl.societyName = participationSelected.facturationSocietyName;
        ctrl.societyAddress = participationSelected.facturationSocietyAddress;
        ctrl.societyAddress2 = participationSelected.facturationSocietyAddress2;
        ctrl.societyCp = participationSelected.facturationSocietyCp;
        ctrl.societyCity = participationSelected.facturationSocietyCity;
      }
    };

    ctrl.ok = function () {
      var participationSelected = _.find(ctrl.participations, function (o) {
        return o.id === ctrl.participationId;
      });

      $uibModalInstance.close({
        participation: participationSelected,
        orderRef: ctrl.orderRef,
        billRef: ctrl.billRef,
        societyName: ctrl.societyName,
        societyAddress: ctrl.societyAddress,
        societyAddress2: ctrl.societyAddress2,
        societyCp: ctrl.societyCp,
        societyCity: ctrl.societyCity
      });
    };
    ctrl.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
