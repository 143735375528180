(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('canceropoleGsoApp')
        .constant('VERSION', "1.2.19")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_DATE', "2025-2-13 16:14:34")
;
})();
