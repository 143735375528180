(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('FormFieldController', FormFieldController);

  FormFieldController.$inject = [
    '$log',
    'ConfirmDialog',
    'DateUtils',
    '$uibModal',
    'CustomUtils',
    '$state',
    'CallForProjectFormField',
    'JQWidgetsService',
    '$translate'
  ];

  function FormFieldController(
    $log,
    ConfirmDialog,
    DateUtils,
    $uibModal,
    CustomUtils,
    $state,
    CallForProjectFormField,
    JQWidgetsService,
    $translate
  ) {
    var ctrl = this;

    ctrl.editable = ctrl.editable === 'true';

    if (angular.isUndefined(ctrl.readOnly)) {
      ctrl.readOnly = false;
    }
    if (angular.isUndefined(ctrl.showEvaluation)) {
      ctrl.showEvaluation = false;
    }
    if (angular.isUndefined(ctrl.editEvaluation)) {
      ctrl.editEvaluation = false;
    }

    ctrl.replaceHTMLEntities = function (str) {
      if (!str) {
        return str;
      }

      var htmlEntities = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&#039;': "'",
        '&ndash;': '-'
      };

      return str.replace(/&[\w#]+;/g, function (entity) {
        return htmlEntities[entity] || entity;
      });
    };

    ctrl.countLeftCharacters = function () {
      if (!angular.isUndefined(this.field.value)) {
        if (ctrl.field.type === 'editor') {
          var nbChar = CustomUtils.countCharInHtmlString(this.field.value);
          return this.field.maxLength - nbChar;
        }
        return this.field.maxLength - this.field.value.length;
      }
      return this.field.maxLength;
    };

    if (ctrl.field.type === 'date') {
      ctrl.dateSettings = {
        culture: 'fr-FR'
      };
      if (!CustomUtils.isEmpty(ctrl.field.value)) {
        ctrl.field.value = DateUtils.convertDateTimeFromServer(ctrl.field.value);
      } else if (!CustomUtils.isEmpty(ctrl.field.defaultValue)) {
        ctrl.field.value = DateUtils.convertDateTimeFromServer(ctrl.field.defaultValue);
      } else {
        ctrl.field.value = new Date();
      }
    } else if (ctrl.field.type === 'checkbox') {
      if (angular.isString(ctrl.field.config)) {
        ctrl.field.config = JSON.parse(ctrl.field.config);
      }
      if (!CustomUtils.isEmpty(ctrl.field.value)) {
        ctrl.field.value = JSON.parse(ctrl.field.value);
        if (angular.isString(ctrl.field.value)) {
          ctrl.field.value = JSON.parse(ctrl.field.value);
        }
      }

      for (var i = 0; i < ctrl.field.config.values.length; i++) {
        var item = ctrl.field.config.values[i];
        if (!CustomUtils.isEmpty(ctrl.field.value)) {
          for (var j = 0; j < ctrl.field.value.length; j++) {
            if (item.value === ctrl.field.value[j]) {
              item.checked = true;
            }
          }
        } else if (!CustomUtils.isEmpty(ctrl.field.defaultValue)) {
          if (item.label === ctrl.field.defaultValue) {
            item.checked = true;
            break;
          }
        }
      }
      ctrl.onCheckBoxChange = function () {
        var checked = [];
        angular.forEach(ctrl.field.config.values, function (item) {
          if (item.checked === true) {
            checked.push(item.value);
          }
        });
        if (checked.length > 0) {
          ctrl.field.value = checked;
        } else {
          ctrl.field.value = null;
        }
      };
    } else if (ctrl.field.type === 'number') {
      ctrl.numberSettings = JSON.parse(ctrl.field.config);
      if (!CustomUtils.isEmpty(ctrl.field.value)) {
        ctrl.field.value = Number(ctrl.field.value);
      } else if (!CustomUtils.isEmpty(ctrl.field.defaultValue)) {
        ctrl.field.value = Number(ctrl.field.defaultValue);
      }
    } else if (ctrl.field.type === 'combobox') {
      if (angular.isString(ctrl.field.config)) {
        ctrl.field.config = JSON.parse(ctrl.field.config);
      }
      ctrl.comboboxSettings = {
        displayMember: 'label',
        valueMember: 'value',
        autoDropDownHeight: true,
        placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
        source: ctrl.field.config.values
      };
      if (CustomUtils.isEmpty(ctrl.field.value) && !CustomUtils.isEmpty(ctrl.field.defaultValue)) {
        ctrl.field.value = ctrl.field.defaultValue;
      }
    } else if (ctrl.field.type === 'radio') {
      if (angular.isString(ctrl.field.config)) {
        ctrl.field.config = JSON.parse(ctrl.field.config);
      }
      if (angular.isString(ctrl.field.defaultValue)) {
        ctrl.field.defaultValue = ctrl.field.defaultValue === 'true';
      }

      if (CustomUtils.isEmpty(ctrl.field.value) && !CustomUtils.isEmpty(ctrl.field.defaultValue)) {
        ctrl.field.value = ctrl.field.defaultValue;
      }
    } else if (ctrl.field.type === 'yesNo') {
      if (angular.isString(ctrl.field.config)) {
        ctrl.field.config = JSON.parse(ctrl.field.config);
      }
      if (CustomUtils.isUndefinedOrNull(ctrl.field.value) && !CustomUtils.isEmpty(ctrl.field.defaultValue)) {
        ctrl.field.value = ctrl.field.defaultValue == 'true';
      }
    } else if (ctrl.field.type === 'ifYes') {
      if (angular.isString(ctrl.field.config)) {
        ctrl.field.config = JSON.parse(ctrl.field.config);
      }
      ctrl.onIfYesChange = function () {
        if (ctrl.field.valueRadio === true) {
          if (angular.isString(ctrl.field.textValue)) {
            ctrl.field.value = ctrl.field.textValue;
          } else {
            ctrl.field.value = null;
          }
        } else if (ctrl.field.valueRadio === false) {
          if (angular.isString(ctrl.field.value)) {
            ctrl.field.textValue = ctrl.field.value;
          }
          ctrl.field.value = false;
        }
      };
    } else if (ctrl.field.type === 'ifNo') {
      if (angular.isString(ctrl.field.config)) {
        ctrl.field.config = JSON.parse(ctrl.field.config);
      }
      ctrl.onIfNoChange = function () {
        if (ctrl.field.valueRadio === true) {
          if (angular.isString(ctrl.field.value)) {
            ctrl.field.textValue = ctrl.field.value;
          }
          ctrl.field.value = true;
        } else if (ctrl.field.valueRadio === false) {
          if (angular.isString(ctrl.field.textValue)) {
            ctrl.field.value = ctrl.field.textValue;
          } else {
            ctrl.field.value = null;
          }
        }
      };
    } else if (ctrl.field.type === 'textarea') {
      var newVAlue = ctrl.replaceHTMLEntities(ctrl.field.value);
      console.log(newVAlue);
      ctrl.field.value = ctrl.replaceHTMLEntities(ctrl.field.value);
    }

    ctrl.preventMaxLength = function () {
      if (!CustomUtils.isUndefinedOrNull(ctrl.field.maxLength) && ctrl.field.value.length >= ctrl.field.maxLength) {
        ctrl.field.value = ctrl.field.value.substring(0, ctrl.field.maxLength);
      }
    };

    ctrl.editField = function () {
      $uibModal
        .open({
          templateUrl: 'app/sheet/callforproject/dialog/add-field.dialog.html',
          controller: 'AddCallForProjectFormFieldDialogController',
          controllerAs: 'ctrl',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('callForProjectFormField');
                $translatePartialLoader.addPart('formFieldType');
                $translatePartialLoader.addPart('criterionType');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
              }
            ],
            formField: function () {
              return ctrl.field;
            },
            groups: function () {
              return ctrl.groups;
            }
          }
        })
        .result.then(function () {
          $state.go($state.current, {}, { reload: true });
        });
    };

    ctrl.tryRemoveField = function () {
      var msg = CustomUtils.removeQuotes(ctrl.field.label);
      ConfirmDialog.openConfirmRemove(msg, function () {
        CallForProjectFormField.delete({ id: ctrl.field.id }, function () {
          $state.go($state.current, {}, { reload: true });
        });
      });
    };

    ctrl.fieldCheckBoxRequired = function () {
      if (ctrl.field.required) {
        if (ctrl.field.value && ctrl.field.value.length > 0) {
          return false;
        }
        return true;
      }
      return false;
    };

    ctrl.field.getEvaluation = function () {
      if (CustomUtils.isUndefinedOrNull(this.criterionValue) && CustomUtils.isUndefinedOrNull(this.criterionComment)) {
        return null;
      }
      return {
        type: this.criterionType,
        value: this.criterionValue,
        comment: this.criterionComment
      };
    };

    if (ctrl.field.criterionType === 'number' || ctrl.field.criterionType === 'onlyNumber') {
      if (!_.isObject(ctrl.field.criterionConfig)) {
        ctrl.field.criterionConfig = JSON.parse(ctrl.field.criterionConfig);
        if (_.isNumber(ctrl.field.criterionConfig)) {
          ctrl.field.criterionConfig = {
            max: ctrl.field.criterionConfig,
            step: 1
          };
        }
      }
    }
  }
})();
